import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Row, Col } from "react-flexbox-grid"

import { media, colors } from "../../uikit"
import theme from "./theme"

const { Logo, href } = theme.featuredOn

const Heading = styled.h2`
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: ${colors.blue.darken};
  text-transform: uppercase;
  margin-bottom: 32px;

  @media screen and ${media.table} {
    margin-top: 26px;
  }

  @media screen and ${media.mobile} {
    font-size: 11.5px;
    margin-top: 15px;
    margin-bottom: 42px;
  }
`

const FeaturedOn = ({ text }) => {
  const [width, setWidth] = useState(175)
  const [height, setHeight] = useState(88)

  useEffect(() => {
    const handler = () => {
      if (window.innerWidth >= 768) {
        setWidth(175)
        setHeight(88)
      } else {
        setWidth(140)
        setHeight(70)
      }
    }

    handler()

    window.addEventListener("resize", handler)

    return () => {
      window.removeEventListener("resize", handler)
    }
  }, [])

  return (
    <>
      <Row center="xs">
        <Col>
          <Heading>{text}</Heading>
        </Col>
      </Row>
      <Row center="xs">
        <Col>
          <a href={href} target="_blank" rel="noopener noreferrer">
            <Logo width={width} height={height} />
          </a>
        </Col>
      </Row>
    </>
  )
}

export default FeaturedOn
