import React from "react"
import styled from "styled-components"

import { Block } from "../../uikit"

import FeaturedOn from "./FeaturedOn"

const Container = styled(Block)`
  margin: 0 !important;
  max-width: 100vw;
  width: 100vw;
`

const LogosContainer = ({ text }) => (
  <Container>
    <FeaturedOn text={text} />
  </Container>
)

export default LogosContainer
