import colors from "../../uikit/theme/colors"
import Illustration1 from "../../images/illustrations/illustration-1.svg"
import Illustration2 from "../../images/illustrations/illustration-2.svg"
import Illustration3 from "../../images/illustrations/illustration-3.svg"
import Illustration4 from "../../images/illustrations/illustration-4.svg"
import Nvidia from "../../images/logos/nvidia-logo.svg"
import GoogleCloud from "../../images/logos/google-cloud-logo.svg"
import TechCrunch from "../../images/logos/tech-crunch.svg"
import MSlogo from "../../images/logos/microsoft-logo.svg"
import Visa from "../../images/logos/visa-logo.svg"
import SAP from "../../images/logos/sap-logo.svg"
import RTP from "../../images/logos/rtp-logo.svg"

export default {
  color: colors.white,
  indents: "100px",
  sponsors: [
    { Logo: RTP, href: "https://rtp.vc" },
    {
      Logo: Nvidia,
      href: "https://www.nvidia.com/en-us/deep-learning-ai/startups/",
    },
    { Logo: MSlogo, href: "https://startups.microsoft.com/en-us/" },
    { Logo: Visa, href: "https://www.visa.com.ru/" },
    { Logo: SAP, href: "https://www.sap.com/index.html" },
    { Logo: GoogleCloud, href: "https://cloud.google.com/developers/startups" },
  ],
  featuredOn: {
    Logo: TechCrunch,
    href:
      "https://techcrunch.com/2019/08/01/dasha-ai-is-calling-so-you-dont-have-to/",
  },
  illustrations: [
    {
      svg: Illustration1,
      desktop: {
        top: "100px",
        left: "-211px",
      },
      tablet: {
        top: "179px",
        left: "-316px",
      },
    },
    {
      svg: Illustration2,
      desktop: {
        top: "290px",
        right: "123px",
      },
      tablet: {
        top: "357px",
        right: "-56px",
      },
    },
    {
      svg: Illustration3,
      desktop: {
        top: "-41px",
        right: "-49px",
      },
      tablet: {
        bottom: "40px",
        right: "-128px",
      },
    },
    {
      svg: Illustration4,
      desktop: {
        top: "25px",
        left: "192px",
      },
      tablet: {
        top: "54px",
        left: "-14px",
      },
    },
  ],
}
