import React from "react"

import LogosContainer from "../MainSection/LogosContainer"
import { Section } from "../../uikit"

const Partners = ({ text }) => (
  <Section style={{ paddingBottom: 60 }}>
    <LogosContainer text={text} />
  </Section>
)

export default Partners
