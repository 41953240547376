import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import Layout from "../layout"

import { useHeaderColor } from "src/common/hooks"
import Partners from "../components/PartnersSection"
import { graphql, useStaticQuery } from "gatsby"
import { useLocale } from "src/common/hooks"
import styled from "styled-components"
import LeftQuote from "../images/company/leftQuote.svg"
import RightQuote from "../images/company/rightQuote.svg"
import Circles from "../images/company/circles.svg"
import { getNodeByLocale } from "../common/getNodesByLocale"
import Button from "../newUikit/Button"
import {
  CONTACT_MODAL,
  getModalClickHandler,
} from "../components/ModalsContainer/events"
import { media } from "src/uikit"

const MissionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 196px;
  background: #f6f6fb;

  .inner {
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
`

const MissionText = styled.div`
  margin-top: 80px;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;

  text-align: center;

  color: #0a1c61;
  width: 100%;
  max-width: 1000px;
  padding: 0 50px;
  z-index: 100;

  @media (max-width: 440px) {
    margin-bottom: 50px;
  }

  @media (max-width: 768px) {
    font-size: 26px;
    line-height: 32px;
  }
`

const MissionQuoteContainer = styled.div`
  max-width: 800px;
  width: calc(100% - 80px);
  background: #ffffff;
  border-radius: 13.7739px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 51px 16px 32px;
  position: absolute;
  z-index: 100;
  bottom: -60px;
  margin: 0 40px;
`

const MissionQuoteContainerShadow = styled.div`
  background: rgba(39, 49, 142, 0.2);
  filter: blur(24.2536px);
  border-radius: 31.4029px;
  position: relative;
  z-index: 50;
  height: 194px;
  max-width: 790px;
  width: calc(100% - 80px);
  bottom: -110px;
`

const MissionCircles = styled.div`
  position: absolute;
  mix-blend-mode: normal;
  opacity: 0.08;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 99;

  @media (max-width: 768px) {
    display: none;
  }
`

const MissionQuote = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;

  text-align: center;
  letter-spacing: 0.4px;
  max-width: 470px;
  width: 100%;
  color: #142470;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`

const MissionQuoteText = styled.div`
  margin: 0 50px;
  flex: 1;
  font-size: 20px;
  line-height: 32px;

  @media (max-width: 768px) {
    margin: 0 25px;
  }
`

const MissionQuoteAuthor = styled.div`
  text-align: center;
  margin-top: 32px;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 32px;

  letter-spacing: 0.5px;

  color: #0a1c61;
`

const LeftQuoteContainer = styled.span`
  flex: 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media (max-width: 768px) {
    svg {
      height: 14px;
      width: 16px;
    }
  }
`
const RightQuoteContainer = styled.span`
  flex: 0;

  display: flex;
  flex-direction: row;
  align-items: flex-end;

  @media (max-width: 768px) {
    svg {
      height: 14px;
      width: 16px;
    }
  }
`

const AfterMissionTextContainer = styled.section`
  padding: 0 16px;
  margin-top: -64px;
  margin-bottom: 80px;

  .inner {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media ${media.tablet} {
    margin-bottom: 64px;
  }

  @media ${media.mobile} {
    margin-bottom: 48px;
  }
`

const AfterMissionText = styled.p`
  font-size: 17px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #0a1c61;
  margin-bottom: 16px;
  max-width: 800px;
  width: 100%;

  :last-child {
    margin-bottom: 0;
  }

  @media ${media.tablet} {
    max-width: 608px;
    text-align: left;
  }
`

const CommandContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 90px;
`

const CommandTitle = styled.div`
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #142470;
  margin-bottom: 40px;
  padding: 0 16px;
`

const CommandRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  > div {
    border-right: 2px solid #dee0ed;
  }

  > div:last-child {
    border-right: none;
  }

  @media (max-width: 339px) {
    > div {
      border-right: none;
    }
  }

  @media (max-width: 509px) {
    > div:nth-child(2) {
      border-right: none;
    }
  }

  @media (min-width: 510px) and (max-width: 679px) {
    > div:nth-child(3) {
      border-right: none;
    }
  }
`
const CommandColumn = styled.div`
  width: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 170px;
  margin-bottom: 30px;
`

const CommandType = styled.div`
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 72px;
  flex: 0 0 72px;
  color: #5855f4;
`

const CommandTypeCount = styled.div`
  margin-top: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  flex: 1;

  text-align: center;
  letter-spacing: 0.4px;

  color: #142470;
`

const ContactUs = styled.div`
  display: flex;
  justify-content: center;
`

const options = {
  renderNode: {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <AfterMissionText>{children}</AfterMissionText>
    ),
  },
}

const ContactUsPage = ({ location }) => {
  const { inverse, isChangeColor } = useHeaderColor({
    defaultInverse: false,
    elem: { selector: "#mission", inverse: false },
  })

  const locale = useLocale()

  const queryResult = useStaticQuery(
    graphql`
      query CompanyQuery {
        allContentfulCompanyPage {
          nodes {
            id
            quote
            quoteAuthor
            bottomCtaButtonName
            missionText {
              json
            }
            counter1
            counter1Description
            counter2
            counter2Description
            counter3
            counter3Description
            counter4
            counter4Description
            faceboardIntoSubTitle
            faceboardIntoTitle
            mainTitle
            node_locale
            teamCounterTitle
            footerLogoTitle
            contactUsButton
          }
        }
      }
    `
  )

  const { nodes } = queryResult.allContentfulCompanyPage
  const data = getNodeByLocale(nodes, locale)

  const headerColor = {
    primaryColor: "#F6F6FB",
    secondaryColor: "#fff",
  }

  const func = getModalClickHandler(CONTACT_MODAL, {
    title: data.contactUsButton,
  })

  return (
    <Layout
      location={location}
      headerColor={headerColor}
      headerInverse={inverse}
      headerIsChangeColor={isChangeColor}
    >
      <MissionContainer>
        <div className="inner">
          <MissionText>{data.mainTitle}</MissionText>
          <MissionQuoteContainer id="mission">
            <MissionQuote>
              <LeftQuoteContainer>
                <LeftQuote />
              </LeftQuoteContainer>
              <MissionQuoteText>{data.quote}</MissionQuoteText>
              <RightQuoteContainer>
                <RightQuote />
              </RightQuoteContainer>
            </MissionQuote>
            <MissionQuoteAuthor>{data.quoteAuthor}</MissionQuoteAuthor>
          </MissionQuoteContainer>
          <MissionQuoteContainerShadow />
          <MissionCircles>
            <Circles />
          </MissionCircles>
        </div>
      </MissionContainer>
      <AfterMissionTextContainer>
        <div className="inner">
          {documentToReactComponents(data.missionText.json, options)}
        </div>
      </AfterMissionTextContainer>
      <CommandContainer>
        <CommandTitle>{data.teamCounterTitle}</CommandTitle>
        <CommandRow>
          <CommandColumn>
            <CommandType>{data.counter1}</CommandType>
            <CommandTypeCount>{data.counter1Description}</CommandTypeCount>
          </CommandColumn>
          <CommandColumn>
            <CommandType>{data.counter2}</CommandType>
            <CommandTypeCount>{data.counter2Description}</CommandTypeCount>
          </CommandColumn>
          <CommandColumn>
            <CommandType>{data.counter3}</CommandType>
            <CommandTypeCount>{data.counter3Description}</CommandTypeCount>
          </CommandColumn>
          <CommandColumn>
            <CommandType>{data.counter4}</CommandType>
            <CommandTypeCount>{data.counter4Description}</CommandTypeCount>
          </CommandColumn>
        </CommandRow>
      </CommandContainer>
      <Partners text={data.footerLogoTitle} />
      <ContactUs style={{ marginBottom: 140 }}>
        <Button onClick={func} color={inverse ? "white" : "purple"}>
          {data.contactUsButton}
        </Button>
      </ContactUs>
    </Layout>
  )
}

export default ContactUsPage
